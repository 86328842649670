import React, {useContext, useRef, useState} from 'react';
import UserContext from './UserContext.js';

// react router
import {useLocation} from 'react-router-dom'

// multilingual
import {AnchorLink, Text} from './MultilingualText.js';
import {
    controlMessageOneKey,
    controlMessageTwoKey,
    controlTitleKey,
    helpKey,
    keysightSoftwareManagerKey,
    mainMessages,
} from '../../assets/text/MultilingualText.js';
import {Popover} from "@progress/kendo-react-tooltip";
import Alert from "./Alert";


function PageHeader() {
    const {
        siteLanguageDefault,
    } = useContext(UserContext);

    const location = useLocation();
    let pathname = location.pathname.slice(-1) === "/" ?
        location.pathname :
        location.pathname.concat("/");

    const AnchorText = AnchorLink(Text);

    return (
        <>
            <div className={"k-page-header"}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    {!pathname.includes("/help") && (
                        <>
                            <div className="k-h1">
                                <Text
                                    textkey={keysightSoftwareManagerKey}
                                    textdefault={mainMessages[siteLanguageDefault][keysightSoftwareManagerKey]}
                                />
                            </div>

                            <div>
                                <AnchorText
                                    to={pathname + 'help'}
                                    target={"_blank"}
                                    style={{
                                        color: "var(--keysight-alternate)",
                                        float: "right",
                                        fontSize: "1.25rem",
                                        fontWeight: 700,
                                        cursor: "pointer",
                                    }}
                                    data-location="PageHeader"
                                    data-name="PageHeader"
                                    data-trigger="false"
                                    data-parentvalue="PageHeader"
                                    textkey={helpKey}
                                    textdefault={mainMessages[siteLanguageDefault][helpKey]}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default PageHeader;