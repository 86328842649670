// react
import React, {useContext, useState} from 'react';
import axios from 'axios';
import config from '../../../config.js';
import UserContext from '../../common/UserContext.js';

// react router
import {useHistory} from 'react-router-dom';

// css
import '../../../assets/css/EARequestAgain.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import Alert from '../../common/Alert.js';
import {RequestAgainModalGrid} from './Grid.js';
import {formatCurrency} from '../../common/utilities.js';

// kendo react
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Button} from '@progress/kendo-react-buttons';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    cancelKey,
    changeAnotherSessionKey,
    changeCannotSaveKey,
    confirmKey,
    contactUsHeaderKey,
    copyProductsAndHostsKey,
    ELCopyProductsWarning3Key,
    ELRequestAgainCopyProductsAndHostsWarningKey,
    endDateQtyEditKey,
    endDateSetKey,
    genericErrorTitleKey,
    mainMessages,
    startDateSetKey,
    subscriptionPoolConsumeKey,
    subscriptionPoolFrom,
    toRequestAgainKey
} from '../../../assets/text/MultilingualText.js';

export const RequestAgainModal = (props) => {
    const {
        pool,
        unSavedPool,
        assignments,
        setIsLoading,
        setIsVisible
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();
    let history = useHistory();

    const [alerts, setAlerts] = useState([]);

    const titleStyle = {
        fontWeight: "normal",
        margin: 0,
        color: "#424242"
    };

    /*
     * showHandler() handler for all alerts
    */
    const showHandler = (index) => setAlerts(alerts.filter((a, i) => i !== index));

    /*
     * close() closes the modal
    */
    const close = () => {
        setIsVisible(false);
    };

    /*
     * confirm() confirms the request again
    */
    const confirm = () => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        let eaId = new URLSearchParams(window.location.search).get('eaid');
        let period = "PRODUCTSELECTIONCURRENTPERIOD";
        let data = {
            module: "EA",
            sub_module: "variable-allocations",
            action: "COPY",
            input_json: [
                {
                    "ea_id": eaId,
                    "products_period_selection_input": period,
                    "copy_selection_input": "COPYLASTPERIODPRODUCTSANDHOSTS",
                    "ea_alloc_ids": assignments.flatMap(a => [a.ea_alloc_id, a.parent_alloc_id])
                }
            ]
        };

        setAlerts([]);
        setIsLoading(true);

        axios.post(
            config.ea_summary.COPY_PRODUCTS,
            data,
            {headers: headers}
        )
            .then((response) => {
                setIsLoading(false);
                if (response.status === 200) {
                    let params = '?eaid=' + eaId + '&show=' + period + '&step=2'
                    history.push('/ea-request-license' + params);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to POST request again", error);
                setIsLoading(false);
                if (error.response.hasOwnProperty('data')) {
                    let errorCode = error.response.data['error_code'].toUpperCase();
                    if (errorCode === 'MOD_EA_158' || errorCode === 'MOD_EA_132') {
                        setAlerts([{
                            type: "error",
                            title: localization.toLanguageString(changeCannotSaveKey, mainMessages[siteLanguageDefault][changeCannotSaveKey]),
                            message: localization.toLanguageString(changeAnotherSessionKey, mainMessages[siteLanguageDefault][changeAnotherSessionKey])
                        }]);
                    } else {
                        setAlerts([{
                            type: "error",
                            title: localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey]),
                            message: localization.toLanguageString(contactUsHeaderKey, mainMessages[siteLanguageDefault][contactUsHeaderKey])
                        }]);
                    }
                } else {
                    setAlerts([{
                        type: "error",
                        title: localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey]),
                        message: localization.toLanguageString(contactUsHeaderKey, mainMessages[siteLanguageDefault][contactUsHeaderKey])
                    }]);
                }
            });
    }

    return (
        <Dialog
            className={" ksm-dialog"}
            title={
                <h2 style={titleStyle}>
                    {localization.toLanguageString(copyProductsAndHostsKey, mainMessages[siteLanguageDefault][copyProductsAndHostsKey])}&nbsp;
                    {localization.toLanguageString(toRequestAgainKey, mainMessages[siteLanguageDefault][toRequestAgainKey])}
                </h2>
            }
            onClose={close}
        >
            <div
                style={{
                    'display': 'flex'
                }}
            >
                <div
                    style={{
                        'fontSize': "3.125rem",
                        'color': "var(--keysight-yellow)",
                    }}
                    className={"k-icon k-i-warning"}>
                </div>
                <div
                    style={{
                        marginLeft: '1rem',
                        fontSize: '1rem',
                        wordBreak: 'normal',
                    }}
                >
                    <Row>
                        <Col>{localization.toLanguageString(ELRequestAgainCopyProductsAndHostsWarningKey, mainMessages[siteLanguageDefault][ELRequestAgainCopyProductsAndHostsWarningKey])}</Col>
                    </Row>
                    <Row className={"my-2"}>
                        <Col>
                            <ul className={"m-0"}>
                                <li>{localization.toLanguageString(startDateSetKey, mainMessages[siteLanguageDefault][startDateSetKey])}</li>
                                <li>{localization.toLanguageString(endDateSetKey, mainMessages[siteLanguageDefault][endDateSetKey])}</li>
                                <li>{localization.toLanguageString(endDateQtyEditKey, mainMessages[siteLanguageDefault][endDateQtyEditKey])}</li>
                                <li>{localization.toLanguageString(subscriptionPoolConsumeKey, mainMessages[siteLanguageDefault][subscriptionPoolConsumeKey])}{formatCurrency(unSavedPool, pool.locale, pool.currency)}{localization.toLanguageString(subscriptionPoolFrom, mainMessages[siteLanguageDefault][subscriptionPoolFrom])}</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className={"my-3"}>
                        <Col>
                            <RequestAgainModalGrid assignments={assignments}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <b>{localization.toLanguageString(ELCopyProductsWarning3Key, mainMessages[siteLanguageDefault][ELCopyProductsWarning3Key])}</b>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {alerts.map((alert, index) => <div className={"my-2"}>
                                {alert.type === 'error' ? <Alert
                                    className={"my-3 p-0"}
                                    key={'error-' + index}
                                    type={'error'}
                                    title={alert.title}
                                    message={alert.message}
                                    showHandler={() => showHandler(index)}
                                /> : <></>}
                            </div>)}
                        </Col>
                    </Row>
                </div>
            </div>
            <DialogActionsBar layout="center">
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    shape={"rectangle"}
                    fillMode={"outline"}
                    rounded={"small"}
                    type={"button"}
                    onClick={close}
                >
                    {localization.toLanguageString(cancelKey, mainMessages[siteLanguageDefault][cancelKey])}
                </Button>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    shape={"rectangle"}
                    fillMode={"solid"}
                    rounded={"small"}
                    type={"button"}
                    onClick={confirm}
                >
                    {localization.toLanguageString(confirmKey, mainMessages[siteLanguageDefault][confirmKey])}
                </Button>
            </DialogActionsBar>
        </Dialog>
    )
}

export default RequestAgainModal;